// Default imports
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import ButtonText from "../components/ButtonText";
import GenericSection from "./GenericSection";
import WpImage from "./WpImage";

const Features = ({ button, topList, iconsList, ...props }) => {
  return (
    <GenericSection vertical {...props}>
      {topList && (
        <Row className="features__lead">
          {topList.map((item, index) => (
            <FeaturesLeadItem key={index} {...item} />
          ))}
        </Row>
      )}

      {iconsList && (
        <div className="features__content">
          {iconsList.map((item, index) => (
            <FeaturesIconItem key={index} {...item} />
          ))}
        </div>
      )}

      {button && (
        <ButtonText className="features__button" variant="red" {...button}>
          {button?.title}
        </ButtonText>
      )}
    </GenericSection>
  );
};

const FeaturesIconItem = ({ header, text, icon, fullWidth }) => (
  <div className={`features__content-item ${fullWidth ? "-full-width" : ""}`}>
    <WpImage image={icon} className="features__content-icon" />
    <h4 className="features__content-header">{header}</h4>
    <div className="features__content-text" dangerouslySetInnerHTML={{ __html: text }} />
  </div>
);

const FeaturesLeadItem = ({ header, text }) => (
  <Col xs="24" lg="8">
    <h3 className="features__lead-header">{header}</h3>
    <p className="features__lead-text">{text}</p>
  </Col>
);

export default Features;
export { FeaturesIconItem, FeaturesLeadItem };
