// Default imports
import React from "react";

import Tabs from "./Tabs";
import GenericSection from "./GenericSection";

const TabsSection = ({ tabsSection, ...props }) => (
  <GenericSection {...tabsSection} {...props}>
    <Tabs tabs={tabsSection?.tabs} />
  </GenericSection>
);

export default TabsSection;
